.home-page {
    display: flex;
    /* justify-content: space-between; */
   
}
  
  .your-tasks-container {
    flex-basis: 45%; /* Adjust the width as needed */
  }
  .your-tasks-container{
    margin-left: 120px;
  }
  .calendar-container{
    margin-right: 70px ;
  }
 


    body {
    background: linear-gradient(
      90deg,
      rgba(48, 16, 255, 1) 0%,
      rgba(100, 115, 255, 1) 100%
    );
    background-image: url("https://wallpaperaccess.com/full/6843282.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
  }
  /*https://wallpaperaccess.com/full/1994605.jpg*/
  /*https://wallpaperaccess.com/full/6843265.jpg*/
  /*https://store-images.s-microsoft.com/image/apps.14501.68688977722932617.c18986e0-354d-49e5-a689-33d52a7432cb.4324e613-281f-4948-bd74-d17321615130?mode=scale&q=90&h=1080&w=1920*/


  @media (max-width: 768px) {
    body{
       height: 100vh;
       /* background-size: 100% 100%; */
       background-position: center;
       background-repeat: no-repeat;
       background-attachment: fixed;
       background-size: cover;
    }
    .home-page {
      flex-direction: column;
      align-items: center;
   }
  
   .your-tasks-container,
   .calendar-container {
      margin: 20px 0;
   }
   
   }



   .todo-app2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 500px;
    min-height: 600px;
  /*  background: #161a2b;*/
    text-align: center;
    margin: 90px auto;
    border-radius: 10px;
    padding-bottom: 32px;
  }
  
@media (max-width: 768px) {
  .todo-app2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 380px;
    min-height: 600px;
  /*  background: #161a2b;*/
    text-align: center;
    margin: 90px auto;
    border-radius: 10px;
    padding-bottom: 32px;
  }
  
}
   