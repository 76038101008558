.logo h1{ 
    letter-spacing: 5px;
    font-size: 5vw;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
    
}
.logo p{
    margin-left: 90px;
    color: aliceblue;
    font-size: 20px;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
    text-align: center;
}

@media (max-width: 768px) {
    .logo p{
        text-align: center;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 16px;
    }  
    .logo h1{
        font-size: 7vw;
    }
    
}