.login-page {
  display: flex;
  min-height: 100vh;
  justify-content: flex-end;
 
  
  background: -webkit-linear-gradient(bottom, #2dbd6e, #a6f77b);
  background-image: url("https://a-static.besthdwallpaper.com/mountains-minimalist-scenery-wallpaper-1920x1200-81093_6.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
 }
 
 .login-card {
  /* Add your desired card styling here */
  /* For example: */
  width: 80%;
  max-width: 400px;
  height: 400px;
  border-radius: 8px;
  margin: auto;
 }
 
 .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  margin-bottom: 50px;
  margin-left: 100px;
 }
 
 @media (max-width: 768px) {
  .login-card {
     height: 300px;
  }
  .login-page{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 15px;
   }

 }