/* Global Box Model */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Container */
.wrap {
  margin: 75px auto 15px;
  position: relative;
  width: 330px; /* Width of the container */
 /* background: white;*/
  border-radius: 10px;
  padding: 196px 32px 15px; /* Top, right, and bottom padding */
  box-shadow: 0 6px 24px hsla(0, 0, 0, 0.1);
  
 /* margin-right: 260px; */
 margin-top: 100px;
}

/* Confirmation */
.confirmation {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 300ms ease 300ms;
  &.visible {
    opacity: 1;
  }
}

.all-good {
  display: block;
  margin: 20px auto 0;
  width: 60px; /* Width of the element */
}



.path {
  stroke-dasharray: 800;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash 0.6s ease-in-out;
    animation: dash 0.6s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 800;
    -webkit-animation: dash 0.6s 0.35s ease-in-out forwards;
    animation: dash 0.6s 0.35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -66.67;
    -webkit-animation: dash-check 0.6s 0.35s ease-in-out forwards;
    animation: dash-check 0.6s 0.35s ease-in-out forwards;
  }
}

.card-description {
  text-align: center;
  margin: 15px 0 40px;
  font-size: 1em;
  &.success {
    color: #777;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 800;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 800;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -66.67;
  }
  100% {
    stroke-dashoffset: 600;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -66.67;
  }
  100% {
    stroke-dashoffset: 600;
  }
}

/* Buttons */
.button-cnt {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.primary-cta,
.secondary-cta {
  border: none;
  position: absolute;
  height: 42.67px;
  z-index: 2;
  top: 160px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  transition: all 150ms ease;
  outline: none !important;
}

.primary-cta {
  background: linear-gradient(0deg, rgba(255,151,0,1) 0%, rgba(251,75,2,1) 100%);
  border-radius: 6.67px;
  box-shadow: 0 6px 24px -6.67px #CD106E;
  color: white;
  padding: 0 16px;
  pointer-events: auto;
  width: 170.67px;
  z-index: 9;
  transition: all 150ms ease;
  will-change: transform, box-shadow, background;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  margin-top: 70px;
}

.secondary-cta {
  background: linear-gradient(0deg, rgba(255,151,0,1) 0%, rgba(251,75,2,1) 100%);
  border-radius: 6.67px;
  padding: 0 16px;
  pointer-events: auto;
  width: 102.67px;
  z-index: 9;
  transition: all 150ms ease;
  will-change: transform, box-shadow, background;
  position: relative;
}

.secondary-cta--done {
  border: none;
  position: absolute;
  height: 42.67px;
  z-index: 2;
  top: 160px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  transition: all 150ms ease;
  outline: none !important;
  color: #fff;
  margin-top: 70px;
}

.secondary-cta--send {
  border: 1px solid #F4157E;
  box-shadow: 0 6px 24px -6.67px #CD106E;
  color: white;
}

.secondary-cta.hidden {
  opacity: 0;
  pointer-events: none;
}

/* Gift Card */
.gift-card-box {
  perspective: 500;
  width: 266.67px; /* Width of the gift card box */
  height: 186.67px; /* Height of the gift card box */
  position: absolute;
  top: -74.67px;
  left: 50%;
  transform: translateX(-50%);

  .flip {
    transform: rotateY(180deg);
  }

  .front {
    background-image: url('https://c4.wallpaperflare.com/wallpaper/52/465/301/illustration-landscape-digital-art-mountains-wallpaper-preview.jpg');
    background-size: cover;
  }

  .front,
  .back {
    width: 266.67px;
    height: 208px;
    border-radius: 10px;
    backface-visibility: hidden;
    position: absolute;
    color: #fff;
    font-family: sans-serif;
    top: 0;
    left: 0;
    box-shadow: 0 0.67px 4px hsla(0, 0, 0, 0.3);
    &.back {
      background-image: url('https://c4.wallpaperflare.com/wallpaper/52/465/301/illustration-landscape-digital-art-mountains-wallpaper-preview.jpg');
      background-size: cover;
    }
  }

  .card {
    transition: 600ms;
    transform-style: preserve-3d;
    position: relative;
    transition: all 0.8s ease-in-out;
    &.whoosh {
      animation: whooshAnimation 1600ms;
    }
  }

  @keyframes whooshAnimation {
    0% {
    }
    50% {
      transform: translateY(-100vh);
    }
    75% {
      transform: translateX(100vw);
    }
    100% {
    }
  }

  .front-logo-cnt {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .font-logo {
    width: 200px;
  }

  .card-description {
    font-family: sans-serif;
    line-height: 1.4;
    text-align: center;
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);
  }
}
.card-description {
  font-family: sans-serif;
  line-height: 1.4;
  text-align: center;
  font-size: 44px;
  color:#fff;
  margin-top: 20px;
  font-weight: bold;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
}
